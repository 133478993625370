/********* Form **********/
[dir=rtl] .was-validated .form-control:invalid, [dir=rtl] .form-control.is-invalid {
    background-position: left calc(0.375em + 0.1875rem) center;
}

[dir=rtl] .formio-component-resource .control-label--hidden.field-required, [dir=rtl] .formio-component-select .control-label--hidden.field-required {
    left: 40px;
    right: unset;
}

[dir=rtl] .choices__item.choices__item--selectable {
    padding-left: 25px;
    padding-right: 0;
}
